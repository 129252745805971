<template>
  <div>
    <inputTextarea
      :data="location"
      :label="$t('settings.location.title')"
      :help="$t('settings.location.info')"
      :callback="setLocation"
      :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
      :placeholder="$t('settings.location.title')"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      location: JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      ).location
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setLocation(ret) {
      this.location = ret;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      data.location = this.location;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
